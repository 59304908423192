import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
import "../scss/header_footer.scss";
import "../scss/business.scss";

// require("html-loader?interpolate!../html/index.html");

// import "./main.tsx"

const objectFitImages = require('object-fit-images');
objectFitImages();

document.addEventListener('DOMContentLoaded', () => {
    const header_nav = document.querySelector<HTMLElement>('header nav');
    const header_nav_button = document.querySelector<HTMLElement>('header nav button');
    const header_nav_ul = document.querySelector<HTMLElement>('header nav ul');
    const md = window.matchMedia('screen and (min-width: 768px)');
    const md2 = window.matchMedia('screen and (max-width: 767px)');

    header_nav_button.addEventListener('click', () => {
        header_nav.classList.toggle('open');
    });

    document.addEventListener('touchmove', () => {
        header_nav.classList.remove('open');
    });

    document.addEventListener('wheel', () => {
        header_nav.classList.remove('open');
    });

    let userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {        
        md.addListener(() => {
            if (md.matches) {
                header_nav.classList.remove('open');
            }
        });
        md2.addListener(() => {
            if (md2.matches) {
                let before = header_nav_ul.style.display;
                header_nav_ul.style.display = 'none';
                setTimeout(() => {
                    header_nav_ul.style.display = before;
                }, 350);
            }
        });
    } else {
        md.addEventListener('change', () => {
            if (md.matches) {
                header_nav.classList.remove('open');
            }
        });
        md2.addEventListener('change', () => {
            if (md2.matches) {
                let before = header_nav_ul.style.display;
                header_nav_ul.style.display = 'none';
                setTimeout(() => {
                    header_nav_ul.style.display = before;
                }, 350);
            }
        });
    }

});